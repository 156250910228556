import {IBaseStateField, IResponse} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {updateById} from '@/utils/updateArrays/updateById'

import {
    IArticleReport,
    ICloseReportReturn,
    IDeleteReportCommentReturn,
    IGetArticleReportReturn,
    IGetCommentsReturn,
    IGetModeratorReportsReturn, IGetReportsWithTranslationInfo,
    IModeratorReportsPage,
    IRefundReportReturn,
    IReportComment,
    IReportInfo,
    IReportReason,
    ReportStatuses,
} from '@/store/actionTypes/reports'
import {
    closeReport,
    createReport,
    createReportComment, deleteCommentReport, getArticleReport, getModeratorReports,
    getReport,
    getReportComments,
    getReportsReasons, refundReport, updateCommentReport,
} from '@/store/entities/reports/actions'
import {deleteReportCommentById} from '@/store/entities/reports/utils/deleteReportCommentById'
import {updateReportById} from '@/store/entities/reports/utils/updateReportById'
import {updateReportCommentById} from '@/store/entities/reports/utils/updateReportCommentById'
import {updateReportCommentsByPage} from '@/store/entities/reports/utils/updateReportCommentsByPage'
import {IReport} from '@/store/types/IReport'

interface ReportsState {
    getReportState: IBaseStateField
    getCommentsState: IBaseStateField
    createCommentState: IBaseStateField
    getReasonsState: IBaseStateField
    createReportState: IBaseStateField
    closeReportState: IBaseStateField
    updateCommentState: IBaseStateField
    deleteCommentState: IBaseStateField
    refundReportState: IBaseStateField
    getArticleReportsState: IBaseStateField & { reportIsNotExist: boolean },
    getModeratorReportsState: IBaseStateField,
    moderatorReportsPage: IModeratorReportsPage | null,
    reports: IReport[]
    reasons: IReportReason[]
    articleReport: IArticleReport | null
    lastCommentsLoadedPage: number | null,
    lastCommentsPage: number | null,
    lastModeratorReportsPage: number | null
}

const initialState: ReportsState = {
    getReportState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    getCommentsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    createCommentState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    getReasonsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    createReportState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    closeReportState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    updateCommentState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    deleteCommentState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    refundReportState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    getArticleReportsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        reportIsNotExist: false
    },
    getModeratorReportsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    moderatorReportsPage: null,
    reports: [],
    reasons: [],
    articleReport: null,
    lastCommentsLoadedPage: null,
    lastCommentsPage: null,
    lastModeratorReportsPage: null
}

export const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setLastLoadedPage: (state, action: PayloadAction<{ page: number }>) => {
            state.lastCommentsLoadedPage = action.payload.page
        },
        setLastPage: (state, action: PayloadAction<{ page: number }>) => {
            state.lastCommentsPage = action.payload.page
        },
        resetGetArticleReportState: (state) => {
            state.getArticleReportsState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
                reportIsNotExist: false
            }
        },
        resetCreateReportState: (state) => {
            state.createReportState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetCreateCommentState: (state) => {
            state.createCommentState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetCloseReportStates: (state) => {
            state.closeReportState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
            state.refundReportState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        }
    },
    extraReducers: {
        // get report
        [getReport.pending.type]: (state) => {
            state.getReportState.isLoading = true
            state.getReportState.isError = false
            state.getReportState.isSuccess = false
        },
        [getReport.fulfilled.type]: (state, action: PayloadAction<IResponse<IReportInfo>>) => {
            state.getReportState.isLoading = false
            state.getReportState.isError = false
            state.getReportState.isSuccess = true

            state.reports = updateReportById(action.payload.result.id, state.reports,
                    report => ({ ...action.payload.result, commentsPages: report.commentsPages }),
                () => ( { ...action.payload.result, commentsPages: [] } ))
        },
        [getReport.rejected.type]: (state) => {
            state.getReportState.isLoading = false
            state.getReportState.isError = true
            state.getReportState.isSuccess = false
        },
        // create comment
        [createReportComment.pending.type]: (state) => {
            state.createCommentState.isLoading = true
            state.createCommentState.isError = false
            state.createCommentState.isSuccess = false
        },
        [createReportComment.fulfilled.type]: (state) => {
            state.createCommentState.isLoading = false
            state.createCommentState.isError = false
            state.createCommentState.isSuccess = true
        },
        [createReportComment.rejected.type]: (state) => {
            state.createCommentState.isLoading = false
            state.createCommentState.isError = true
            state.createCommentState.isSuccess = false
        },
        // get comments
        [getReportComments.pending.type]: (state) => {
            state.getCommentsState.isLoading = true
            state.getCommentsState.isError = false
            state.getCommentsState.isSuccess = false
        },
        [getReportComments.fulfilled.type]: (state, action: PayloadAction<IGetCommentsReturn>) => {
            state.getCommentsState.isLoading = false
            state.getCommentsState.isError = false
            state.getCommentsState.isSuccess = true
            state.reports = updateReportById(action.payload.reportId, state.reports, report => {
                if (state.lastCommentsLoadedPage && action.payload.pagination.page > state.lastCommentsLoadedPage) state.lastCommentsLoadedPage = action.payload.pagination.page
                else if (!state.lastCommentsLoadedPage) state.lastCommentsLoadedPage = action.payload.pagination.page
                return { ...report, commentsPages: updateReportCommentsByPage(action.payload.pagination.page, report.commentsPages,
                        (page) => ({ ...page, comments: action.payload.comments }),
                        () => ( { pageNumber: action.payload.pagination.page, comments: action.payload.comments } )) }
            })
            state.lastCommentsPage = action.payload.pagination.total_pages
        },
        [getReportComments.rejected.type]: (state) => {
            state.getCommentsState.isLoading = false
            state.getCommentsState.isError = true
            state.getCommentsState.isSuccess = false
        },
        // get reasons
        [getReportsReasons.pending.type]: (state) => {
            state.getReasonsState.isLoading = true
            state.getReasonsState.isError = false
            state.getReasonsState.isSuccess = false
        },
        [getReportsReasons.fulfilled.type]: (state, action: PayloadAction<IReportReason[]>) => {
            state.getReasonsState.isLoading = false
            state.getReasonsState.isError = false
            state.getReasonsState.isSuccess = true
            state.reasons = action.payload
        },
        [getReportsReasons.rejected.type]: (state) => {
            state.getReasonsState.isLoading = false
            state.getReasonsState.isError = true
            state.getReasonsState.isSuccess = false
        },
        // create report
        [createReport.pending.type]: (state) => {
            state.createReportState.isLoading = true
            state.createReportState.isError = false
            state.createReportState.isSuccess = false
        },
        [createReport.fulfilled.type]: (state) => {
            state.createReportState.isLoading = false
            state.createReportState.isError = false
            state.createReportState.isSuccess = true
        },
        [createReport.rejected.type]: (state) => {
            state.createReportState.isLoading = false
            state.createReportState.isError = true
            state.createReportState.isSuccess = false
        },
        // close report
        [closeReport.pending.type]: (state) => {
            state.closeReportState.isLoading = true
            state.closeReportState.isError = false
            state.closeReportState.isSuccess = false
        },
        [closeReport.fulfilled.type]: (state, action: PayloadAction<ICloseReportReturn>) => {
            state.closeReportState.isLoading = false
            state.closeReportState.isError = false
            state.closeReportState.isSuccess = true
            state.reports = updateReportById(action.payload.id, state.reports, report => ({ ...report, status: ReportStatuses.closed }))
        },
        [closeReport.rejected.type]: (state) => {
            state.closeReportState.isLoading = false
            state.closeReportState.isError = true
            state.closeReportState.isSuccess = false
        },
        // update comment
        [updateCommentReport.pending.type]: (state) => {
            state.updateCommentState.isLoading = true
            state.updateCommentState.isError = false
            state.updateCommentState.isSuccess = false
        },
        [updateCommentReport.fulfilled.type]: (state, action: PayloadAction<IReportComment>) => {
            state.updateCommentState.isLoading = false
            state.updateCommentState.isError = false
            state.updateCommentState.isSuccess = true
            state.reports = updateReportCommentById(action.payload.id, state.reports, comment => ({ ...comment, text: action.payload.text }))
        },
        [updateCommentReport.rejected.type]: (state) => {
            state.updateCommentState.isLoading = false
            state.updateCommentState.isError = true
            state.updateCommentState.isSuccess = false
        },
        // delete comment
        [deleteCommentReport.pending.type]: (state) => {
            state.deleteCommentState.isLoading = true
            state.deleteCommentState.isError = false
            state.deleteCommentState.isSuccess = false
        },
        [deleteCommentReport.fulfilled.type]: (state, action: PayloadAction<IDeleteReportCommentReturn>) => {
            state.deleteCommentState.isLoading = false
            state.deleteCommentState.isError = false
            state.deleteCommentState.isSuccess = true
            state.reports = deleteReportCommentById(action.payload.id, state.reports)
        },
        [deleteCommentReport.rejected.type]: (state) => {
            state.deleteCommentState.isLoading = false
            state.deleteCommentState.isError = true
            state.deleteCommentState.isSuccess = false
        },
        // refund report
        [refundReport.pending.type]: (state) => {
            state.refundReportState.isLoading = true
            state.refundReportState.isError = false
            state.refundReportState.isSuccess = false
        },
        [refundReport.fulfilled.type]: (state, action: PayloadAction<IRefundReportReturn>) => {
            state.refundReportState.isLoading = false
            state.refundReportState.isError = false
            state.refundReportState.isSuccess = true
            state.reports = updateReportById(action.payload.id, state.reports, report => ({ ...report, status: ReportStatuses.refunded }))
        },
        [refundReport.rejected.type]: (state) => {
            state.refundReportState.isLoading = false
            state.refundReportState.isError = true
            state.refundReportState.isSuccess = false
        },
        // get article reports
        [getArticleReport.pending.type]: (state) => {
            state.getArticleReportsState.isLoading = true
            state.getArticleReportsState.isError = false
            state.getArticleReportsState.isSuccess = false
            state.getArticleReportsState.reportIsNotExist = false
        },
        [getArticleReport.fulfilled.type]: (state, action: PayloadAction<IGetArticleReportReturn>) => {
            state.getArticleReportsState.isLoading = false
            state.getArticleReportsState.isError = false
            state.getArticleReportsState.isSuccess = true
            state.getArticleReportsState.reportIsNotExist = false

            state.reports = updateReportById(action.payload.reportInfo.id, state.reports,
                report => ( {...report, ...action.payload.reportInfo} ),
                () => ({ ...action.payload.reportInfo, commentsPages: [] })
            )

            state.articleReport = {
                id: action.payload.articleId,
                report: action.payload.reportInfo
            }
        },
        [getArticleReport.rejected.type]: (state, action: PayloadAction<{ code: number | null }>) => {
            state.getArticleReportsState.isLoading = false
            state.getArticleReportsState.isError = true
            state.getArticleReportsState.isSuccess = false
            state.getArticleReportsState.reportIsNotExist = action.payload.code === 404
        },
        // get moderator reports
        [getModeratorReports.pending.type]: (state) => {
            state.getModeratorReportsState.isLoading = true
            state.getModeratorReportsState.isError = false
            state.getModeratorReportsState.isSuccess = false
        },
        [getModeratorReports.fulfilled.type]: (state, action: PayloadAction<IGetModeratorReportsReturn>) => {
            state.getModeratorReportsState.isLoading = false
            state.getModeratorReportsState.isError = false
            state.getModeratorReportsState.isSuccess = true

            action.payload.reports.forEach(info => {
                state.reports = updateById(state.reports, info.report.id,
                        report => ({ ...info.report, commentsPages: report.commentsPages }),
                        () => ({ ...info.report, commentsPages: [] })
                )
            })

            state.moderatorReportsPage = {
                page: action.payload.pagination.page,
                reportsId: action.payload.reports.map(info => info.report.id),
            }

            state.lastModeratorReportsPage = action.payload.pagination.total_pages
        },
        [getModeratorReports.rejected.type]: (state) => {
            state.getModeratorReportsState.isLoading = false
            state.getModeratorReportsState.isError = true
            state.getModeratorReportsState.isSuccess = false


        },
    }
})

export default reportsSlice.reducer
