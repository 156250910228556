import {
    ITransaction,
    ITransactionsResponse,
    ITransactionsState,
} from '@/store/actionTypes/transactions'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getTransactions} from './actions'
const initialState: ITransactionsState = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: null,
    transactions: [],
    pagination: null,
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {},
    extraReducers: {
        [getTransactions.pending.type]: (state) => {
            state.isLoading = true
            state.isError = false
        },
        [getTransactions.fulfilled.type]: (
            state,
            action: PayloadAction<ITransactionsResponse<ITransaction>>
        ) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.transactions = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getTransactions.rejected.type]: (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
            state.message = action.payload?.message
        },
    },
})
