
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchArticles, deleteArticle } from './actions';
import { IPagination } from '@/types/type';
import {ArticleType} from '@/store/entities/articles/article/slice'
interface ArticleListState {
  articles: ArticleType[];
  pagination: IPagination | null;
  isLoading: boolean;
  isDeleteSuccess: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}

const initialState: ArticleListState = {
  articles: [],
  pagination: null,
  isLoading: false,
  isDeleteSuccess: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};
export const articleListSlice = createSlice({
  name: 'articleList',
  initialState,
  reducers: {
    resetDeleteSuccess: (state) => {
      state.isDeleteSuccess = false;
    },
  },
  extraReducers: {
    [fetchArticles.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
    },
    [fetchArticles.fulfilled.type]: (state, action: PayloadAction<{ articles: ArticleType[]; pagination: IPagination }>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.articles = action.payload.articles;
      state.pagination = action.payload.pagination;
    },
    [fetchArticles.rejected.type]: (state, action: PayloadAction<string | undefined>) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload || 'Ошибка при получении статей';
    },
    [deleteArticle.fulfilled.type]: (state, action: PayloadAction<number>) => {
      state.articles = state.articles.filter(article => article.id !== action.payload);
      state.isDeleteSuccess = true;
    },
    [deleteArticle.rejected.type]: (state, action: PayloadAction<string | undefined>) => {
      state.isError = true;
      state.isDeleteSuccess = false;
      state.errorMessage = action.payload || 'Ошибка при удалении статьи';
    },
  },
});
export const { resetDeleteSuccess } = articleListSlice.actions;
export const articleListReducer = articleListSlice.reducer;
