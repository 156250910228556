import {combineReducers} from 'redux'

import {articleCombineReducer} from './entities/articles'
import authReducer from './entities/auth/authSlice'
import reportsReducer from './entities/reports/reportsSlice'
import contactsReducer from './entities/notifications/contactsSlice'
import notificationsReducer from './entities/notifications/notificationsSlice'
import {balanceReducer} from './entities/balance'
import {dashboardReducer} from './entities/dashboard'
import languagesReducer from './entities/languages/languagesSlice'
// import {documentsReducer} from './entities/documents'
// import {faqCombineReducer} from './entities/faq'
import {layoutReducer} from './entities/layout'
import ordersReducer from './entities/orders/ordersSlice'
import settingsReducer from './entities/settings/settingsSlice'
import translationReducer from './entities/translation/translationSlice'

// import { marginsReducer } from './entities/margins'
// import {reasonsReducer} from './entities/reasons'
// import {settingsReducer} from './entities/settings'
// import {statusesReducer} from './entities/statuses'
// import {usersReducer} from './entities/users'
// import {categoriesCombineReducer} from '@/store/entities/categories'
// import {clientsCombineReducer} from '@/store/entities/clients'
// import {discountsCombineReducer} from '@/store/entities/discounts'
// import {exportDataReducer} from '@/store/entities/exportData'
// import {importDataReducer} from '@/store/entities/importData'
// import {notificationsReducer} from '@/store/entities/notifications'
// import {ordersCombineReducer} from '@/store/entities/orders'
import {paymentsReducer} from '@/store/entities/payments'
import {productsReducer} from '@/store/entities/products'
import { purchaseReducer } from '@/store/entities/purchase'
import { promptsReducer } from './entities/prompts'
import { configsReducer } from './entities/configs'
import { activeConfigsReducer } from './entities/activeConfigs'
import {transactionsReducer} from './entities/transactions'
// import {promocodesCombineReducer} from '@/store/entities/promocodes'
// import {rolesCombineReducer} from '@/store/entities/roles'
// import {rubricatorReducer} from '@/store/entities/rubricator'
// import {shiftsCombineReducer} from '@/store/entities/shifts'
// import {shopsCombineReducer} from '@/store/entities/shops'

// ROOT REDUCER EXAMPLE

// 1. Import your reducers from entities

// import cartReducer from './entities/cart/reducers';

// 2. Define reducers into common object
const rootReducer = combineReducers({
    articles: articleCombineReducer,
    auth: authReducer,
    reports: reportsReducer,
    balance: balanceReducer,
    contacts: contactsReducer,
    notifications: notificationsReducer,
    // users: usersReducer,
    // rubricator: rubricatorReducer,
    // reasons: reasonsReducer,
    // documents: documentsReducer,
    // statuses: statusesReducer,
    // settings: settingsReducer,
    dashboard: dashboardReducer,
    // ...faqCombineReducer,
    // ...clientsCombineReducer,
    products: productsReducer,
    purchase: purchaseReducer,
    prompts: promptsReducer,
    configs: configsReducer,
    activeConfigs: activeConfigsReducer,
    // ...ordersCombineReducer,
    // ...shopsCombineReducer,
    // ...categoriesCombineReducer,
    // ...shiftsCombineReducer,
    payments: paymentsReducer,
    // ...promocodesCombineReducer,
    // ...discountsCombineReducer,
    // notifications: notificationsReducer,
    // importData: importDataReducer,
    // exportData: exportDataReducer,
    // ...rolesCombineReducer,
    layout: layoutReducer,
    // margins: marginsReducer
    orders: ordersReducer,
    languages: languagesReducer,
    settings: settingsReducer,
    transactions: transactionsReducer,
    translation: translationReducer,
})

export default rootReducer
export type IRootReducer = ReturnType<typeof rootReducer>
