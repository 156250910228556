
import { IItemResponse, IListResponse } from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import { updateById } from '@/utils/updateArrays/updateById'

import { deletePromptById, getListOfPrompts, getPromptById, postCreateNewPrompts,putPromptById } from './actions'

import { IPrompt, IPromptDeleteResponse, IPromptsState } from '@/store/actionTypes/prompts'

const initialState: IPromptsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    prompts: [],
    pagination: null,
    prompt: null,
    deleteMessage: null,
    updateSuccess: false,
    updateError: false
}

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState,
    reducers: {
    },
    extraReducers: {
        // Взять список промтов
        [getListOfPrompts.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getListOfPrompts.fulfilled.type]: (state, action: PayloadAction<IListResponse<IPrompt[]>>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.prompts = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getListOfPrompts.rejected.type]: (state) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        // Взять промт по айди
        [getPromptById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IPrompt, 'prompt'>>) => {
            state.prompt = action.payload.data.prompt
        },
        // Обновить промт по айди
        [putPromptById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IPrompt, 'prompt'>>) => {
            state.updateSuccess = true
            state.updateError = false
            state.prompts = updateById(state.prompts, action.payload.data.prompt.id, (prompt) => {return { ...prompt, ...action.payload.data.prompt }})
        },
        [putPromptById.rejected.type]: (state) => {
            state.updateError = true
            state.updateSuccess = false
        },
        [postCreateNewPrompts.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IPrompt, 'prompt'>>) => {
            const newPrompt = action.payload.data.prompt
            state.prompts.push(newPrompt)
        },
        // Удалить промт по айди 
        [deletePromptById.fulfilled.type]: (state, action: PayloadAction<IPromptDeleteResponse>) => {
            state.deleteMessage = action.payload.data.message
            state.prompts = state.prompts.filter(prompt => prompt.id !== action.payload.prompt_id);
        },
    },
})

export default promptsSlice.reducer
