import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

import {
    ICloseReportParams,
    ICreateReportCommentParams,
    ICreateReportParams,
    IDeleteReportCommentParams, IGetModeratorReportsParams,
    IGetArticleReportsParams,
    IGetReportCommentsParams,
    IGetReportParams,
    IRefundReportParams,
    IUpdateReportCommentParams,
} from '@/store/actionTypes/reports'

export const getReport = createAsyncThunk('reports/getReport',
    async (args: IGetReportParams, thunkAPI) => {
    try {
        const response = await Api.Report.getReportReportsReportIdGet(args.id)
        return { result: response.data.data.report.report}
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const createReportComment = createAsyncThunk('reports/createReportComment', async (args: ICreateReportCommentParams, thunkAPI) => {
    try {
        const response = await Api.Report.createCommentReportsReportIdCommentsPost(args.reportId, { text: args.text })
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getReportComments = createAsyncThunk('reports/getReportComments',
    async ({reportId, senderId, page, perPage, sortBy = 'created_at', orderBy = 'asc'}: IGetReportCommentsParams, thunkAPI) => {
    try {
        const response = await Api.Report.getCommentsReportsReportIdCommentsGet(reportId, senderId, page, perPage, sortBy, orderBy)
        return { reportId, comments: response.data.data.list, pagination: { ...response.data.pagination } }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getReportsReasons = createAsyncThunk('reports/getReportReasons', async (_, thunkAPI) => {
    try {
        const response = await Api.Report.getReasonsReportsReasonsGet()
        return response.data.data.list
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const createReport = createAsyncThunk('reports/createReport', async (args: ICreateReportParams, thunkAPI) => {
    try {
        const response = await Api.Report.createReportReportsPost(args)
        return response.data.data.report.report
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const closeReport = createAsyncThunk('reports/closeReport', async (args: ICloseReportParams, thunkAPI) => {
    try {
        await Api.Report.closeReportReportsReportIdClosePost(args.id)
        return args
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const updateCommentReport = createAsyncThunk('reports/updateCommentReport', async (args: IUpdateReportCommentParams, thunkAPI) => {
    try {
        const response = await Api.Report.updateCommentReportsCommentsCommentIdPut(args.comment_id, { text: args.text } )
        return response.data.data.comment
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const deleteCommentReport = createAsyncThunk('reports/deleteCommentReport', async (args: IDeleteReportCommentParams, thunkAPI) => {
    try {
        await Api.Report.deleteCommentReportsCommentsCommentIdDelete(args.id)
        return args
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const refundReport = createAsyncThunk('reports/refundReport', async (args: IRefundReportParams, thunkAPI) => {
    try {
        await Api.Report.refundTranslationReportsReportIdRefundPost(args.id)
        return { id: args.id }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getArticleReport = createAsyncThunk('reports/getArticleReport', async (args: IGetArticleReportsParams, thunkAPI) => {
    try {
        const response = await Api.Report.getReportByArticleIdReportsArticleArticleIdGet(args.id)
        return { reportInfo: response.data.data.report.report, articleId: args.id }
    } catch (e) {
        const error = e as AxiosError
        return thunkAPI.rejectWithValue({code: error.response?.status});
    }
})

export const getModeratorReports = createAsyncThunk('reports/getModeratorReports',
    async ({status, perPage, page, closedByUserId, orderBy, sortBy, reasonId, articleId}: IGetModeratorReportsParams,
           thunkAPI) => {
    try {
        const response =
            await Api.Report.getAllReportsReportsGet(status, articleId, reasonId, closedByUserId, page, perPage, sortBy, orderBy)
        return { pagination: response.data.pagination, reports: response.data.data.list }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})
