import {IReportComment} from '@/store/actionTypes/reports'
import {IReport} from '@/store/types/IReport'

export const deleteReportCommentById = (id: string, reports: IReport[]) => {
    return reports.map(report => {
        return {...report, commentsPages: report.commentsPages.map(page => {
            const newComments: IReportComment[] = []
            page.comments.forEach(comment => {
                if (comment.id !== id) newComments.push(comment)
            })
            return { ...page, comments: newComments }
        })
        }
    })
}
