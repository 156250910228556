import {FC, useEffect, useLayoutEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {TokenService} from '@/api/TokenService'

import {AppFonts} from '@/utils/AppFonts'
import MantineProviders from '@/utils/MantineProviders'

import AppRouter from '@/routes/AppRouter'

import '@/utils/i18n'

import {api} from '@/index'
import {useActions, useAppSelector} from '@/store/hooks'
import {RolesId} from '@/constants/roles/roles'

const App: FC = () => {
    const {setToken} = useActions()
    const token = TokenService.getToken()
    const refreshToken = TokenService.getRefreshedToken()
    const { user } = useAppSelector(state => state.auth)
    const { getBalance } = useActions()

    useLayoutEffect(() => {
        api.init()
        setToken({token, refreshToken})
    }, [])

    useEffect(() => {
        if (user && user.user_info.role_id === RolesId.user) getBalance()
    }, [user])

    return (
        <MantineProviders>
            <AppFonts />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </MantineProviders>
    )
}

export default App
