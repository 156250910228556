import {IBaseStateField, IPagination} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {IGetLanguagesReturn, ILanguage} from '@/store/actionTypes/languages'
import {getLanguages} from '@/store/entities/languages/actions'

interface ILanguagesState {
    getLanguagesState: IBaseStateField
    languages: ILanguage[]
    pagination: IPagination
    chosenIdLanguageList: number[]
}

const initialState: ILanguagesState = {
    getLanguagesState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    languages: [],
    pagination: {
        total_items: 0,
        page: 1,
        items_per_page: 10,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
    },
    chosenIdLanguageList: [],
}

export const languagesSlice = createSlice({
    name: 'languages',
    initialState: initialState,
    reducers: {
        addLanguageId: (state, action: PayloadAction<number>) => {
            state.chosenIdLanguageList = [...state.chosenIdLanguageList, action.payload]
        },
        removeLanguageId: (state, action: PayloadAction<number>) => {
            state.chosenIdLanguageList = state.chosenIdLanguageList.filter(
                (id) => id !== action.payload
            )
        },
    },
    extraReducers: {
        [getLanguages.pending.type]: (state) => {
            state.getLanguagesState.isLoading = true
            state.getLanguagesState.isSuccess = false
            state.getLanguagesState.isError = false
        },
        [getLanguages.fulfilled.type]: (state, action: PayloadAction<IGetLanguagesReturn>) => {
            state.languages = action.payload.data.list
            state.pagination = action.payload.pagination
            state.getLanguagesState.isLoading = false
            state.getLanguagesState.isSuccess = true
            state.getLanguagesState.isError = false
        },
        [getLanguages.rejected.type]: (state) => {
            state.getLanguagesState.isLoading = false
            state.getLanguagesState.isSuccess = false
            state.getLanguagesState.isError = true
        },
    },
})

export default languagesSlice.reducer
