import {IReportComment} from '@/store/actionTypes/reports'
import {IReport} from '@/store/types/IReport'

export const updateReportCommentById = (id: string, reports: IReport[], callback: (comment: IReportComment) => IReportComment) => {
    return reports.map(report => {
        return {...report, commentsPages: report.commentsPages.map(page => {
                return { ...page, comments: page.comments.map(comment => {
                        if (comment.id === id) return callback(comment)
                        return comment
                    })
                }
            })
        }
    })
}
