import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

import {IGetTranslationsParams} from '@/store/actionTypes/translation'

export const getTranslations = createAsyncThunk(
    'translations/getTranslations',
    async (
        {
            promptId,
            configId,
            targetLanguage,
            sourceLanguage,
            page,
            perPage,
            sortBy,
            orderBy,
        }: IGetTranslationsParams,
        thunkAPI
    ) => {
        try {
            const response = await Api.Translation.getTranslationsTranslationGet(
                promptId,
                configId,
                targetLanguage,
                sourceLanguage,
                page,
                perPage,
                sortBy,
                orderBy
            )
            return {
                translations: response.data.data.list,
                pagination: response.data.pagination,
            }
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
