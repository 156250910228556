import {authActions} from './entities/auth'
import {balanceActions} from './entities/balance'
// import {clientsActions} from './entities/clients/clients'
// import {dashboardActions} from './entities/dashboard'
// import {documentsActions} from './entities/documents'
// import {faqActionsCombine} from './entities/faq'
import {reportsActions} from '@/store/entities/reports'
import {layoutActions} from './entities/layout'
import {ordersActions} from './entities/orders'
// import { marginsActions } from './entities/margins'
// import {reasonsActions} from './entities/reasons'
// import {settingsActions} from './entities/settings'
// import {statusesActions} from './entities/statuses'
// import {usersActions} from './entities/users'

import articlesActions from './entities/articles'

// import {categoriesActions} from '@/store/entities/categories/categories'
// import {categoryActions} from '@/store/entities/categories/category'
// import {clientActions} from '@/store/entities/clients/client'
// import {discountsActions} from '@/store/entities/discounts/discounts'
// import {exportDataActions} from '@/store/entities/exportData'
// import {importDataActions} from '@/store/entities/importData'
// import {notificationsActions} from '@/store/entities/notifications'
// import {ordersActionsCombine} from '@/store/entities/orders'
import { purchaseActions } from './entities/purchase'
import { promptsActions } from './entities/prompts';
import { configsActions } from './entities/configs';
import { activeConfigsActions } from './entities/activeConfigs';

import {languagesActions} from '@/store/entities/languages'
import {paymentsActions} from '@/store/entities/payments'
import {productsActionsCombine} from '@/store/entities/products'
import {settingsActions} from '@/store/entities/settings'
import {transactionsActions} from './entities/transactions'
import {notificationsActions} from '@/store/entities/notifications'
import {translationActions} from '@/store/entities/translation'
// import {promocodesActions} from '@/store/entities/promocodes/promocodes'
// import {rolesActionsCombine} from '@/store/entities/roles'
// import {rubricatorActions} from '@/store/entities/rubricator'
// import {shiftActionsCombine} from '@/store/entities/shifts'
// import {shopActions} from '@/store/entities/shops/shop'
// import {shopsActions} from '@/store/entities/shops/shops'

export default {
    // ...usersActions,
    ...authActions,
    ...balanceActions,
    ...reportsActions,
    ...settingsActions,
    ...notificationsActions,
    ...balanceActions,
    ...ordersActions,
    ...languagesActions,
    ...articlesActions,
    // ...clientsActions,
    // ...clientActions,
    ...productsActionsCombine,
    ...transactionsActions,
    // ...rubricatorActions,
    // ...shopsActions,
    // ...shopActions,
    // ...categoriesActions,
    // ...shiftActionsCombine,
    // ...categoryActions,
    // ...ordersActionsCombine,
    ...paymentsActions,
    ...purchaseActions,
    ...promptsActions,
    ...configsActions,
    ...activeConfigsActions,
    // ...promocodesActions,
    // ...discountsActions,
    // ...notificationsActions,
    // ...reasonsActions,
    // ...documentsActions,
    // ...statusesActions,
    // ...faqActionsCombine,
    // ...settingsActions,
    // ...dashboardActions,
    // ...importDataActions,
    // ...exportDataActions,
    // ...rolesActionsCombine,
    ...layoutActions,
    ...translationActions,
    // ...marginsActions,
}
