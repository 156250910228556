import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService';
import { IFetchArticleByIdParams, IUpdateArticleParams } from '@/store/actionTypes/articles';

export const fetchArticleById = createAsyncThunk(
  'articles/fetchArticleById',
  async (params: IFetchArticleByIdParams, thunkAPI) => {
    const { id } = params;
    try {
      const { data } = await Api.Articles.getArticleArticlesArticleIdGet(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при получении статьи');
    }
  }
);

export const updateArticle = createAsyncThunk(
  'articles/updateArticle',
  async ({ id, articleData }: IUpdateArticleParams, thunkAPI) => {
    try {
      const { data } = await Api.Articles.updateArticleArticlesArticleIdPut(id, articleData);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при обновлении статьи');
    }
  }
);