import {FC, ReactNode} from 'react'
import {List, Text} from '@mantine/core'
import {Link} from 'react-router-dom'

interface ISiteMapItem {
    children?: ReactNode
    link: string
}

const SiteMapItem: FC<ISiteMapItem> = props => {
    return (
        <List.Item>
            <Link to={props.link}>
                <Text fz={'md'}>
                    {props.children}
                </Text>
            </Link>
        </List.Item>
    )
}

export default SiteMapItem
