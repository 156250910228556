import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

import {IGetLanguagesParams} from '@/store/actionTypes/languages'

export const getLanguages = createAsyncThunk(
    'languages/getLanguages',
    async ({name, page, perPage}: IGetLanguagesParams, thunkAPI) => {
        try {
            const response = await Api.Language.getLanguagesLanguagesGet(name, page, perPage)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
