import { IBaseStateField } from '@/types/type';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchArticleById, updateArticle } from './actions';

export interface ArticleType {
  id: number;
  title: string;
  text: string;
  is_original: boolean
}
interface FetchArticleResponse {
  data: {
    article: ArticleType;
  };
}
interface ArticleState {
  article: ArticleType | null;
  fetchState: IBaseStateField;
  updateState: IBaseStateField;
  errorMessage: string | null;
}

const initialState: ArticleState = {
  article: null,
  fetchState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  errorMessage: null,
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    resetArticleState: (state) => {
      state.article = null;
      state.fetchState.isError = false;
      state.fetchState.isSuccess = false;
      state.updateState.isError = false;
      state.updateState.isSuccess = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [fetchArticleById.pending.type]: (state) => {
      state.fetchState.isLoading = true;
      state.fetchState.isSuccess = false;
      state.fetchState.isError = false;
      state.errorMessage = null;
    },
    [fetchArticleById.fulfilled.type]: (state, action: PayloadAction<FetchArticleResponse>) => {
      state.fetchState.isLoading = false;
      state.fetchState.isSuccess = true;
      state.article = action.payload.data.article;
    },
    [fetchArticleById.rejected.type]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.isError = true;
      state.errorMessage = action.error.message || 'Ошибка при получении статьи';
    },

    // Update Article
    [updateArticle.pending.type]: (state) => {
      state.updateState.isLoading = true;
      state.updateState.isSuccess = false;
      state.updateState.isError = false;
      state.errorMessage = null;
    },
    [updateArticle.fulfilled.type]: (state) => {
      state.updateState.isLoading = false;
      state.updateState.isSuccess = true;
    },
    [updateArticle.rejected.type]: (state, action) => {
      state.updateState.isLoading = false;
      state.updateState.isError = true;
      state.errorMessage = action.error.message || 'Ошибка при обновлении статьи';
    },
  },
});

export const { resetArticleState } = articleSlice.actions;

export const articleReducer = articleSlice.reducer;
