import {IReportCommentPage} from '@/store/actionTypes/reports'

export const updateReportCommentsByPage = (pageNumber: number, commentsPages: IReportCommentPage[],
                                           callbackFind: (page: IReportCommentPage) => IReportCommentPage,
                                           callbackNotFind?: () => IReportCommentPage) => {
    const pageIndex = commentsPages.findIndex(page => page.pageNumber === pageNumber)
    if (pageIndex !== -1){
        return commentsPages.map((page, i) => {
                if (i === pageIndex) return callbackFind(page)
                return page
            })
    }
    if (callbackNotFind) return [...commentsPages, callbackNotFind()]
    return commentsPages
}
