import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {createItem, createPriceToItem, getItems, getPricesByItemId} from './actions'

import {IPrice, IProduct, IProductsResponse, IProductsState} from '@/store/actionTypes/products'

const initialState: IProductsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    products: [],
    pagination: null,
    loadedProductsPages: [],
    prices: {
        item_id: null,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: '',
        prices: [],
    },
    createPrice: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: '',
    },
    createProduct: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: '',
    },
}

export const productsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {},
    extraReducers: {
        [getItems.pending.type]: (state) => {
            state.isLoading = true
            state.isError = false
        },
        [getItems.fulfilled.type]: (state, action: PayloadAction<IProductsResponse<IProduct>>) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.products = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getItems.rejected.type]: (state) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
        },
        [getPricesByItemId.pending.type]: (state) => {
            state.prices.isLoading = true
            state.prices.isError = false
            state.createPrice.isSuccess = false
        },
        [getPricesByItemId.fulfilled.type]: (
            state,
            action: PayloadAction<IProductsResponse<IPrice> & {item_id: number}>
        ) => {
            state.prices.isLoading = false
            state.prices.isSuccess = true
            state.prices.isError = false
            state.prices.item_id = action.payload.item_id
            state.prices.prices = action.payload.data.list
        },
        [getPricesByItemId.rejected.type]: (state, action) => {
            state.prices.isError = true
            state.prices.isLoading = false
            state.prices.isSuccess = false
            state.prices.message = action.payload?.message
        },
        [createPriceToItem.pending.type]: (state) => {
            state.createPrice.isLoading = true
            state.createPrice.isError = false
            state.createPrice.isSuccess = false
        },
        [createPriceToItem.fulfilled.type]: (state) => {
            state.createPrice.isLoading = false
            state.createPrice.isSuccess = true
            state.createPrice.isError = false
        },
        [createPriceToItem.rejected.type]: (state, action) => {
            state.createPrice.isError = true
            state.createPrice.isLoading = false
            state.createPrice.isSuccess = false
            state.createPrice.message = action.payload?.message
        },
        [createItem.pending.type]: (state) => {
            state.createProduct.isLoading = true
            state.createProduct.isError = false
            state.createProduct.isSuccess = false
        },
        [createItem.fulfilled.type]: (state) => {
            state.createProduct.isLoading = false
            state.createProduct.isSuccess = true
            state.createProduct.isError = false
        },
        [createItem.rejected.type]: (state, action) => {
            state.createProduct.isError = true
            state.createProduct.isLoading = false
            state.createProduct.isSuccess = false
            state.createProduct.message = action.payload?.message
        },
    },
})

export default productsSlice.reducer
