import { combineReducers } from 'redux';
import * as articleActions from './article/actions';
import * as articleFormActions from './article-form/actions';
import * as articleListActions from './article-list/actions';

import { articleSlice } from './article/slice';
import { articleFormSlice } from './article-form/slice';
import { articleListSlice } from './article-list/slice';

export const articleCombineReducer = combineReducers({
  article: articleSlice.reducer,
  articleForm: articleFormSlice.reducer,
  articleList: articleListSlice.reducer,
});


const articlesActions = {
    ...articleActions,
    ...articleFormActions,
    ...articleListActions,
  ...articleSlice.actions,
  ...articleFormSlice.actions,
  ...articleListSlice.actions,
};

export type ArticleState = ReturnType<typeof articleCombineReducer>;

export default articlesActions;
export const { resetArticleState, resetArticleFormState, resetDeleteSuccess } = articlesActions;