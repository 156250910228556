import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { Api } from "@/api/apiService";
import { PromptSchema } from "@/api/swaggerApi/translator";

import { IGetPromptById } from "@/store/actionTypes/prompts";

export const getListOfPrompts = createAsyncThunk('/prompts', async (page: number, thunkAPI) => {
    try {
        const response = await Api.Prompt.getPromptsPromptsGet(page)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const postCreateNewPrompts = createAsyncThunk('/prompts/postCreateNewPrompts', async (arg: PromptSchema, thunkAPI) => {
    try {
        const response = await Api.Prompt.createPromptPromptsPost(arg)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getPromptById = createAsyncThunk('/prompts/getPromptById', async (arg: IGetPromptById, thunkAPI) => {
    try {
        const response = await Api.Prompt.getPromptPromptsPromptIdGet(arg)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const putPromptById = createAsyncThunk('/prompts/putPromptById', async ({promptId, body }:{promptId:number, body:PromptSchema} , thunkAPI) => {
    try {
        const response = await Api.Prompt.updatePromptPromptsPromptIdPut(promptId, body)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const deletePromptById = createAsyncThunk('/prompts/deletePromptById', async (promptId: number, thunkAPI) => {
    try {
        const response = await Api.Prompt.deletePromptPromptsPromptIdDelete(promptId)
        return {
            data: response.data,
            prompt_id: promptId
        }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})