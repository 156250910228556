import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

import {IUpdateSubscriptionsParams} from '@/store/actionTypes/settings'

export const getSubscriptions = createAsyncThunk('settings/getSubscriptions',
    async (_, thunkAPI) => {
    try {
        const response = await Api.Settings.getSubscriptionSettingsSettingsSubscriptionsGet()
        return response.data.data.settings
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const updateSubscriptions = createAsyncThunk('settings/updateSubscriptions',
    async (args: IUpdateSubscriptionsParams, thunkAPI) => {
        try {
            const response = await Api.Settings.updateSubscriptionSettingsSettingsSubscriptionsPut(args)
            return response.data.data.settings
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    })
