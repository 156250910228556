import {IPagination} from '@/types/type'
import {IPage} from '@/types/type'

export const ReportStatuses = {
    open: 1,
    refunded: 3,
    closed: 2,
} as const;

export interface IReportComment {
    id: string
    text: string
    sender_id: string
    report_id: number
}

export interface IReportTranslationInfo {
    original_text: string,
    translation_text: string,
    source_language: number,
    target_language: number
}

export interface IReportReason {
    id: number
    text: string
}

export interface IReportCommentPage {
    pageNumber: number
    comments: IReportComment[]
}

export interface IReportInfo {
    id: number
    text: string
    article_id: number
    status: number
    reason_id: number
    closed_by_user_id: string
}

export interface IArticleReport {
    id: number
    report: IReportInfo
}

export interface IReportPagination {
    total_items: number
    page: number
    items_per_page: number
    next_page: number
    prev_page: number
    total_pages: number
}

export interface IModeratorReportsPage extends IPage<'reportsId', number>{}

export interface IGetCommentsReturn{
    reportId: number
    comments: IReportComment[]
    pagination: IReportPagination
}

export interface IGetArticleReportReturn {
    articleId: number
    reportInfo: IReportInfo
}

export interface IGetModeratorReportsReturn {
    pagination: IPagination,
    reports: IGetReportsWithTranslationInfo[]
}

export interface ICloseReportReturn {
    id: number
}

export interface IRefundReportReturn {
    id: number
}

export interface ICreateReportParams {
    article_id: number
    reason_id: number
    text: string
}

export interface ICreateReportCommentParams {
    reportId: number
    text: string
}

export interface IDeleteReportCommentReturn {
    id: string
}

export interface IGetReportCommentsParams {
    reportId: number
    page?: number
    perPage?: number
    sortBy?: 'created_at' | 'updated_at'
    orderBy?: 'desc' | 'asc'
    senderId?: string
}

export interface IGetReportParams {
    id: number
}

export interface ICloseReportParams {
    id: number
}

export interface IUpdateReportCommentParams {
    comment_id: string
    text: string
}

export interface IDeleteReportCommentParams {
    id: string
}

export interface IRefundReportParams {
    id: number
}

export interface IGetArticleReportsParams {
    id: number
}

export interface IGetModeratorReportsParams {
    status?: number
    articleId?: number
    reasonId?: number
    closedByUserId?: string
    page?: number
    perPage?: number
    sortBy?: 'created_at' | 'updated_at'
    orderBy?: 'asc' | 'desc'
}

export interface IGetReportsWithTranslationInfo {
    report: IReportInfo
    translation_info: IReportTranslationInfo
}
