import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {updateById} from '@/utils/updateArrays/updateById'

import {
    ICalculateOrdersPriceReturn,
    IConfirmOrdersReturn, IGetOrderContentReturn,
    IGetOrdersReturn,
    IOrder,
    IOrdersPage,
    OrderStatuses,
} from '@/store/actionTypes/orders'
import {
    calculateOrdersPrice,
    confirmOrders,
    createOrder, getOrderContent,
    getOrders,
} from '@/store/entities/orders/actions'

interface IOrdersState {
    getOrdersState: IBaseStateField
    confirmOrdersState: IBaseStateField
    orders: IOrder[]
    ordersPage: IOrdersPage | null
    totalPages: number | null
    chosenIdArticleList: number[]
    createOrderState: IBaseStateField
    calculatePriceState: IBaseStateField,
    ordersPrice: number | null,
    ordersToConfirm: IOrder[],
    getOrderContentState: IBaseStateField,
    orderContent: Pick<IGetOrderContentReturn, 'articles' | 'languages'>,
}

const initialState: IOrdersState = {
    getOrdersState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    confirmOrdersState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    createOrderState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    orders: [],
    ordersPage: null,
    totalPages: null,
    chosenIdArticleList: [],
    calculatePriceState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    ordersPrice: null,
    ordersToConfirm: [],
    getOrderContentState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    orderContent: {
        articles: [],
        languages: [],
    }
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        resetConfirmOrdersState: (state) => {
            state.confirmOrdersState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetGetOrdersState: (state) => {
            state.getOrdersState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        addArticleId: (state, action: PayloadAction<number>) => {
            state.chosenIdArticleList = [...state.chosenIdArticleList, action.payload]
        },
        removeArticleId: (state, action: PayloadAction<number>) => {
            state.chosenIdArticleList = state.chosenIdArticleList.filter(
                (id) => id !== action.payload
            )
        },
        resetCalculatePriceState: (state) => {
            state.calculatePriceState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetCreateOrderState: (state) => {
            state.createOrderState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        }
    },
    extraReducers: {
        [getOrders.pending.type]: (state) => {
            state.getOrdersState.isLoading = true
            state.getOrdersState.isSuccess = false
            state.getOrdersState.isError = false
        },
        [getOrders.fulfilled.type]: (state, action: PayloadAction<IGetOrdersReturn>) => {
            state.getOrdersState.isLoading = false
            state.getOrdersState.isSuccess = true
            state.getOrdersState.isError = false

            state.totalPages = action.payload.pagination.total_pages
            action.payload.orders.forEach((newOrder) => {
                state.orders = updateById(
                    state.orders,
                    newOrder.id,
                    (order) => ({...order, ...newOrder}),
                    () => newOrder
                )
            })
            state.ordersPage = {
                page: action.payload.pagination.page,
                orders: action.payload.orders.map((order) => order.id),
            }
        },
        [getOrders.rejected.type]: (state) => {
            state.getOrdersState.isLoading = false
            state.getOrdersState.isSuccess = false
            state.getOrdersState.isError = true
        },
        [confirmOrders.pending.type]: (state) => {
            state.confirmOrdersState.isLoading = true
            state.confirmOrdersState.isSuccess = false
            state.confirmOrdersState.isError = false
        },
        [confirmOrders.fulfilled.type]: (state, action: PayloadAction<IConfirmOrdersReturn>) => {
            state.confirmOrdersState.isLoading = false
            state.confirmOrdersState.isSuccess = true
            state.confirmOrdersState.isError = false
            action.payload.orders.forEach((order) => {
                state.orders = updateById(state.orders, order.id, (order) => ({
                    ...order,
                    status: OrderStatuses.confirmed,
                }))
            })
        },
        [confirmOrders.rejected.type]: (state) => {
            state.confirmOrdersState.isLoading = false
            state.confirmOrdersState.isSuccess = false
            state.confirmOrdersState.isError = true
        },
        [createOrder.pending.type]: (state) => {
            state.createOrderState.isLoading = true
            state.createOrderState.isSuccess = false
            state.createOrderState.isError = false
        },
        [createOrder.fulfilled.type]: (state, action: PayloadAction<IOrder[]>) => {
            state.createOrderState.isLoading = false
            state.createOrderState.isSuccess = true
            state.createOrderState.isError = false
            state.ordersToConfirm = action.payload
        },
        [createOrder.rejected.type]: (state) => {
            state.createOrderState.isLoading = false
            state.createOrderState.isSuccess = false
            state.createOrderState.isError = true
        },
        // calculate price
        [calculateOrdersPrice.pending.type]: (state) => {
            state.calculatePriceState.isLoading = true
            state.calculatePriceState.isSuccess = false
            state.calculatePriceState.isError = false
        },
        [calculateOrdersPrice.fulfilled.type]: (state, action: PayloadAction<ICalculateOrdersPriceReturn>) => {
            state.calculatePriceState.isLoading = false
            state.calculatePriceState.isSuccess = true
            state.calculatePriceState.isError = false
            state.ordersPrice = action.payload.tokens
        },
        [calculateOrdersPrice.rejected.type]: (state) => {
            state.calculatePriceState.isLoading = false
            state.calculatePriceState.isSuccess = false
            state.calculatePriceState.isError = true
        },
        // getOrderContent
        [getOrderContent.pending.type]: (state) => {
            state.getOrderContentState.isLoading = true
            state.getOrderContentState.isSuccess = false
            state.getOrderContentState.isError = false
        },
        [getOrderContent.fulfilled.type]: (state, action: PayloadAction<IGetOrderContentReturn>) => {
            state.getOrderContentState.isLoading = false
            state.getOrderContentState.isSuccess = true
            state.getOrderContentState.isError = false
            state.orderContent = {
                articles: action.payload.articles,
                languages: action.payload.languages.filter(language => action.payload.languagesId.includes(language.id))
            }
        },
        [getOrderContent.rejected.type]: (state) => {
            state.getOrderContentState.isLoading = false
            state.getOrderContentState.isSuccess = false
            state.getOrderContentState.isError = true
        },
    },
})

export default ordersSlice.reducer
