import { IBaseStateField } from "@/types/type"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {updateById} from '@/utils/updateArrays/updateById'

import {
    IContact,
    IGetContactsReturn,
    IUpdateContactReturn,
} from '@/store/actionTypes/notifications'
import { IContactsPagination } from "@/store/actionTypes/notifications"
import {
    addContact,
    deleteContact,
    getContacts,
    updateContact,
} from '@/store/entities/notifications/actions'


interface ContactsState {
    getContactsState: IBaseStateField
    contactsPage: IContactsPagination | null
    lastContactsPage: number | null
    addContactsState: IBaseStateField
    deleteContactState: IBaseStateField
    updateContactState: IBaseStateField
    contactToUpdate: IContact | null
}

const initialState: ContactsState = {
    getContactsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    addContactsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    deleteContactState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    updateContactState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    contactsPage: null,
    lastContactsPage: null,
    contactToUpdate: null
}

export const contactsSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        setContactToUpdate: (state, action: PayloadAction<IContact | null>) => {
            state.contactToUpdate = action.payload
        },
        resetUpdateContactState: (state) => {
            state.updateContactState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetAddContactState: (state) => {
            state.addContactsState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetGetContactsState: (state) => {
            state.getContactsState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetDeleteContactState: (state) => {
            state.deleteContactState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        }
    },
    extraReducers: {
        // get contacts
        [getContacts.pending.type]: (state) => {
            state.getContactsState.isLoading = true
            state.getContactsState.isSuccess = false
            state.getContactsState.isError = false
        },
        [getContacts.fulfilled.type]: (state, action: PayloadAction<IGetContactsReturn>) => {
            state.getContactsState.isLoading = false
            state.getContactsState.isSuccess = true
            state.getContactsState.isError = false

            state.contactsPage = {
                page: action.payload.pagination.page,
                contacts: action.payload.contacts.map(contact => ({ ...contact, recipient: contact.contact_details }))
            }

            state.lastContactsPage = action.payload.pagination.total_pages
        },
        [getContacts.rejected.type]: (state) => {
            state.getContactsState.isLoading = false
            state.getContactsState.isSuccess = false
            state.getContactsState.isError = true
        },
        // create contact
        [addContact.pending.type]: (state) => {
            state.addContactsState.isLoading = true
            state.addContactsState.isSuccess = false
            state.addContactsState.isError = false
        },
        [addContact.fulfilled.type]: (state) => {
            state.addContactsState.isLoading = false
            state.addContactsState.isSuccess = true
            state.addContactsState.isError = false
        },
        [addContact.rejected.type]: (state) => {
            state.addContactsState.isLoading = false
            state.addContactsState.isSuccess = false
            state.addContactsState.isError = true
        },
        // delete contact
        [deleteContact.pending.type]: (state) => {
            state.deleteContactState.isLoading = true
            state.deleteContactState.isSuccess = false
            state.deleteContactState.isError = false
        },
        [deleteContact.fulfilled.type]: (state) => {
            state.deleteContactState.isLoading = false
            state.deleteContactState.isSuccess = true
            state.deleteContactState.isError = false
        },
        [deleteContact.rejected.type]: (state) => {
            state.deleteContactState.isLoading = false
            state.deleteContactState.isSuccess = false
            state.deleteContactState.isError = true
        },
        [updateContact.pending.type]: (state) => {
            state.updateContactState.isLoading = true
            state.updateContactState.isSuccess = false
            state.updateContactState.isError = false
        },
        [updateContact.fulfilled.type]: (state, action: PayloadAction<IUpdateContactReturn>) => {
            state.updateContactState.isLoading = false
            state.updateContactState.isSuccess = true
            state.updateContactState.isError = false

            if (state.contactsPage) {
                state.contactsPage = {
                    ...state.contactsPage,
                    contacts: updateById(state.contactsPage.contacts, action.payload.contact.id,
                        contact => ({ ...action.payload.contact, recipient: action.payload.contact.contact_details, id: contact.id }))
                }
            }
        },
        [updateContact.rejected.type]: (state) => {
            state.updateContactState.isLoading = false
            state.updateContactState.isSuccess = false
            state.updateContactState.isError = true
        },
    },
})

export default contactsSlice.reducer
