import {FC, FormEvent, useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Link, useNavigate} from 'react-router-dom'
import {
    Anchor,
    Box,
    Button,
    Divider,
    Group,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    ThemeIcon,
} from '@mantine/core'
import {useForm} from '@mantine/form'
import {showNotification} from '@mantine/notifications'
import {BrandFacebook, BrandGithub, BrandGoogle, BrandInstagram, Mail} from 'tabler-icons-react'

import {regexp} from '@/utils/regexpUtils'

import {AppPaths} from '@/routes/routes-enums'

import {useActions, useAppSelector} from '@/store/hooks'

interface FormValues {
    email: string
    password: string
}

const SignInForm: FC = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {isLoading, isSuccess, isError, token, loginError, refreshToken} = useAppSelector(
        (store) => store.auth
    )
    const {login, setToken} = useActions()

    const form = useForm<FormValues>({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (value) => {
                if (value.length === 0) return t('Signup.AlertMessage.Email should not be empty')
                if (!value.match(regexp.email)) return t('Signup.AlertMessage.Incorrect email')
                return null
            },
            password: (value) =>
                value.length > 0 ? null : t('Signup.AlertMessage.The password must not be empty'),
        },
    })

    const handleLogin = async (values: FormValues, event: FormEvent) => {
        event.preventDefault()
        const {email, password} = values
        login({email, password})
    }

    useEffect(() => {
        if (isSuccess) {
            setToken({token: token, refreshToken: refreshToken})
            navigate(AppPaths.HOME)
        } else if (isError) {
            if (loginError) {
                // Object.values(loginError).forEach((error) => {
                //     form.setFieldError(error.field, error.message)
                // })
                form.setFieldError('login', t('auth.loginError'))
                showNotification({
                    message: t('auth.loginError'),
                    color: 'red',
                })
            } else {
                form.setFieldError('password', t('auth.authPasswordError'))
                showNotification({
                    message: t('auth.passwordError'),
                    color: 'red',
                })
            }
        }
        // return () => {
        //     reset()
        // }
    }, [isSuccess, isError, loginError])

    return (
        <Paper radius='md' p='xl' withBorder>
            <Text size='lg' fw={500}>
                {t('Signup.Register with')}
            </Text>

            {/* <Group grow mb='md' mt='md'>
                <Button variant='outline' radius='xl' leftIcon={<BrandGithub size={18} />}>
                    Github
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandGoogle size={18} />}>
                    Google
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandFacebook size={18} />}>
                    Facebook
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandInstagram size={18} />}>
                    Instagram
                </Button>
            </Group>

            <Divider
                label={t('Signup.Or continue with phone number, email')}
                labelPosition='center'
                my='lg'
            /> */}

            <form onSubmit={form.onSubmit((values, event) => handleLogin(values, event))}>
                <TextInput
                    label={t('UsersPage.workerForm.phone email')}
                    {...form.getInputProps('email')}
                    size='md'
                    rightSection={
                        <ThemeIcon
                            variant='light'
                            size={'sm'}
                            style={{backgroundColor: 'transparent', color: '#495057'}}
                        >
                            <Mail strokeWidth={1} />
                        </ThemeIcon>
                    }
                    mt={15}
                />
                <PasswordInput
                    mt={12}
                    label={t('Signup.Password')}
                    {...form.getInputProps('password')}
                    size='md'
                />
                <Group mt='xl' position='apart'>
                    <Anchor
                        component='button'
                        type='button'
                        c='dimmed'
                        size='xs'
                        onClick={() => navigate(AppPaths.REGISTRATION)}
                    >
                        <Trans>{t('Signup.Not Register, Register')}</Trans>
                    </Anchor>
                    <Button type='submit' radius='xl'>
                        {t('Signup.Login')}
                    </Button>
                </Group>
                <Box mt={'sm'} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Text
                        component={Link}
                        to={AppPaths.RESET}
                        align={'center'}
                        fz={12}
                        td={'underline'}
                    >
                        {t('NewPassword.Reset password')}
                    </Text>
                </Box>
            </form>
        </Paper>
    )
}

export default SignInForm
