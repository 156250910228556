import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createArticle } from './actions';
import { IBaseStateField } from '@/types/type';
interface ArticleFormState {
  formState: IBaseStateField;
  errorMessage: string;
}

const initialState: ArticleFormState = {
  formState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  errorMessage: '',
};

export const articleFormSlice = createSlice({
  name: 'articleForm',
  initialState,
  reducers: {
    resetArticleFormState: (state) => {
      state.formState.isLoading = false;
      state.formState.isError = false;
      state.formState.isSuccess = false;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [createArticle.pending.type]: (state) => {
      
      state.formState.isLoading = true;
      state.formState.isError = false;
      state.formState.isSuccess = false;
      state.errorMessage = '';
    },
    [createArticle.fulfilled.type]: (state) => {
      state.formState.isLoading = false;
      state.formState.isSuccess = true;
    },
    [createArticle.rejected.type]: (state, action: PayloadAction<string>) => {
      state.formState.isLoading = false;
      state.formState.isError = true;
      state.errorMessage = action.payload || 'Ошибка при создании статьи';
    },
  },
});

export const { resetArticleFormState } = articleFormSlice.actions;
export const articleFormReducer = articleFormSlice.reducer;