import { IBaseStateField } from "@/types/type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deleteNotification, getNotifications } from "./actions";
import { IGetNotificationsReturn, INotificationsPage } from "@/store/actionTypes/notifications";

interface NotificationsState {
    getNotificationsState: IBaseStateField
    notificationsPage: INotificationsPage | null
    lastNotificationsPage: number | null
    deleteNotificationState: IBaseStateField
}

const initialState: NotificationsState = {
    getNotificationsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    deleteNotificationState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    notificationsPage: null,
    lastNotificationsPage: null,
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        resetDeleteNotificationState: (state) => {
            state.deleteNotificationState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
    },
    extraReducers: {
        // getNotifications
        [getNotifications.pending.type]: (state) => {
            state.getNotificationsState.isLoading = true
            state.getNotificationsState.isSuccess = false
            state.getNotificationsState.isError = false
        },
        [getNotifications.fulfilled.type]: (state, action: PayloadAction<IGetNotificationsReturn>) => {
            state.getNotificationsState.isLoading = false
            state.getNotificationsState.isSuccess = true
            state.getNotificationsState.isError = false

            state.notificationsPage = {
                page: action.payload.pagination.page,
                notifications: action.payload.notifications
            }
            state.lastNotificationsPage = action.payload.pagination.total_pages
        },
        [getNotifications.rejected.type]: (state) => {
            state.getNotificationsState.isLoading = false
            state.getNotificationsState.isSuccess = false
            state.getNotificationsState.isError = true
        },
        // deleteNotification
        [deleteNotification.pending.type]: (state) => {
            state.deleteNotificationState.isLoading = true
            state.deleteNotificationState.isSuccess = false
            state.deleteNotificationState.isError = false
        },
        [deleteNotification.fulfilled.type]: (state) => {
            state.deleteNotificationState.isLoading = false
            state.deleteNotificationState.isSuccess = true
            state.deleteNotificationState.isError = false
        },
        [deleteNotification.rejected.type]: (state) => {
            state.deleteNotificationState.isLoading = false
            state.deleteNotificationState.isSuccess = false
            state.deleteNotificationState.isError = true
        },
    }
})

export default notificationsSlice.reducer