import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { Api } from "@/api/apiService";
import { ActiveConfigSchema } from "@/api/swaggerApi/translator";

import { ActiveConfigFilter } from "@/store/actionTypes/activeConfigs";

export const getListOfActiveConfigs = createAsyncThunk('/active-configs', async (arg: ActiveConfigFilter | null , thunkAPI) => {
    try {
        const response = await Api.ActiveConfig.getActiveConfigsActiveConfigsGet(arg?.isActive, arg?.promptId, arg?.configId, arg?.page)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const postCreateNewActiveConfig = createAsyncThunk('/active-configs/postCreateNewActiveConfig', async (arg: ActiveConfigSchema, thunkAPI) => {
    try {
        const response = await Api.ActiveConfig.createActiveConfigActiveConfigsPost(arg)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getActiveConfigById = createAsyncThunk('/active-configs/getActiveConfigsById', async (activeConfigId: number, thunkAPI) => {
    try {
        const response = await Api.ActiveConfig.getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const putActiveConfigById = createAsyncThunk('/active-configs/putActiveConfigById', async ({activeConfigId, body }:{activeConfigId:number, body:ActiveConfigSchema} , thunkAPI) => {
    try {
        const response = await Api.ActiveConfig.updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId, body)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const deleteActiveConfigById = createAsyncThunk('/active-configs/deleteActiveConfigById', async (activeConfigId: number, thunkAPI) => {
    try {
        const response = await Api.ActiveConfig.deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId)
        return {
            data: response.data,
            activeConfigId: activeConfigId
        }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})