import {IReport} from '@/store/types/IReport'

export const updateReportById = (id: number, reports: IReport[],
                                 callbackFind: (report: IReport) => IReport,
                                 callbackNotFind?: () => IReport) => {
    const reportIndex = reports.findIndex(report => report.id === id)
    if (reportIndex !== -1){
        return reports.map((report, i) => {
            if (i === reportIndex) return callbackFind(report)
            return report
        })
    }
    if (callbackNotFind) return [...reports, callbackNotFind()]
    return reports
}
