import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosError } from "axios"

import { Api } from '@/api/apiService'

import { IGetNotificationsParams } from "@/store/actionTypes/notifications"
import {
    IDeleteContactParams,
    IGetContactsParams,
    IUpdateContactParams,
    IDeleteNotificationParams,
} from '@/store/actionTypes/notifications'
import { IAddContacts } from "@/store/actionTypes/notifications"

export const getNotifications = createAsyncThunk('notification/notifications/?page=1&per_page=10&sort_by=created_at&order_by=desc',
    async (args: IGetNotificationsParams, thunkAPI) => {
        try {
            const response = await Api.Notification.getNotificationsNotificationNotificationsGet(args.page, args.per_page, args.sort_by, args.order_by)
            return { pagination: response.data.pagination, notifications: response.data.data.list }
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const deleteNotification = createAsyncThunk('notification/notifications',
    async (args: IDeleteNotificationParams, thunkAPI) => {
        try {
            const response = await Api.Notification.deleteNotificationNotificationNotificationsNotificationIdDelete(args.id)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const getContacts = createAsyncThunk('notifications/getContacts',
    async (args: IGetContactsParams, thunkAPI) => {
        try {
            const response = await Api.Notification.getContactsNotificationContactsGet(args.page, args.items_per_page)
            return {pagination: response.data.pagination, contacts: response.data.data.list}
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const addContact = createAsyncThunk('notifications/addContact',
    async (args: IAddContacts, thunkAPI) => {
        try {
            const response = await Api.Notification.addContactNotificationContactsPost(args.contact)
            return {data: response.data}
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const deleteContact = createAsyncThunk('notifications/deleteContact',
    async (args: IDeleteContactParams, thunkAPI) => {
        try {
            const response = await Api.Notification.updateContactActivityNotificationContactsContactIdDelete(args.id)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const updateContact = createAsyncThunk('notifications/updateContact',
    async ({ id, ...args }: IUpdateContactParams, thunkAPI) => {
        try {
            const response = await Api.Notification.updateContactNotificationContactsContactIdPut(id, args)
            return response.data.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
