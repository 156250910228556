import {Role} from '@/utils/roles/getRoleString'

import {AppPaths} from '@/routes/routes-enums'

export type AuthType = 'notAuth' | 'auth' | 'any' | Role

export interface ISiteMapNavigationElement {
    name: string,
    link: string,
    authType: AuthType,
}

export const siteMapNavigation: ISiteMapNavigationElement[] = [
    {
        name: 'Авторизация',
        link: AppPaths.LOGIN,
        authType: 'notAuth'
    },
    {
        name: 'Регистрация',
        link: AppPaths.REGISTRATION,
        authType: 'notAuth'
    },
    {
        name: 'Заказы',
        link: AppPaths.ORDERS,
        authType: 'user'
    },
]
