
import { IItemResponse, IListResponse } from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import { updateById } from '@/utils/updateArrays/updateById'

import { deleteConfigById, getConfigById, getListOfConfigs, postCreateNewConfig, putConfigById } from './actions'

import { IConfig, IConfigDeleteResponse, IConfigsState } from '@/store/actionTypes/configs'
const initialState: IConfigsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    configs: [],
    config: null,
    pagination: null,
    updateSuccess: false,
    updateError: false,
    deleteMessage: ''
}

export const configsSlice = createSlice({
    name: 'configsSlice',
    initialState,
    reducers: {
    },
    extraReducers: {
        // Взять список конфигураций
        [getListOfConfigs.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getListOfConfigs.fulfilled.type]: (state, action: PayloadAction<IListResponse<IConfig[]>>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.configs = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getListOfConfigs.rejected.type]: (state) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        // Взять конфигурацию по айди
        [getConfigById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IConfig, 'config'>>) => {
            state.config = action.payload.data.config
        },
        // Обновить конфигурацию по айди
        [putConfigById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IConfig, 'config'>>) => {
            state.updateSuccess = true
            state.updateError = false
            state.configs = updateById(state.configs, action.payload.data.config.id, (config) => {return { ...config, ...action.payload.data.config }})
        },
        [putConfigById.rejected.type]: (state) => {
            state.updateError = true
            state.updateSuccess = false
        },
        [postCreateNewConfig.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IConfig, 'config'>>) => {
            const newConfig = action.payload.data.config
            state.configs.push(newConfig)
        },
        // Удалить конфигурацию по айди 
        [deleteConfigById.fulfilled.type]: (state, action: PayloadAction<IConfigDeleteResponse>) => {
            state.deleteMessage = action.payload.data.message
            state.configs = state.configs.filter(config => config.id !== action.payload.configId);
        },
    },
})

export default configsSlice.reducer
