
import { IItemResponse, IListResponse } from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import { updateById } from '@/utils/updateArrays/updateById'

import { deleteActiveConfigById, getActiveConfigById, getListOfActiveConfigs, postCreateNewActiveConfig, putActiveConfigById } from './actions'

import { IActiveConfig, IActiveConfigDeleteResponse, IActiveConfigsState } from '@/store/actionTypes/activeConfigs'

const initialState: IActiveConfigsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    activeConfigs: [],
    pagination: null,
    activeConfig: null,
    updateSuccess: false,
    updateError: false,
    deleteMessage: '',
}

export const activeConfigsSlice = createSlice({
    name: 'activeConfigs',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getListOfActiveConfigs.pending.type]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getListOfActiveConfigs.fulfilled.type]: (state, action: PayloadAction<IListResponse<IActiveConfig[]>>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.activeConfigs = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getListOfActiveConfigs.rejected.type]: (state) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
         [getActiveConfigById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IActiveConfig, 'active_config'>>) => {
            state.activeConfig = action.payload.data.active_config
        },
        [putActiveConfigById.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IActiveConfig, 'active_config'>>) => {
            state.updateSuccess = true
            state.updateError = false
            state.activeConfigs = updateById(state.activeConfigs, action.payload.data.active_config.id, (activeConfig) => {return { ...activeConfig, ...action.payload.data.active_config }})
        },
        [putActiveConfigById.rejected.type]: (state) => {
            state.updateError = true
            state.updateSuccess = false
        },
        [postCreateNewActiveConfig.fulfilled.type]: (state, action: PayloadAction<IItemResponse<IActiveConfig, 'active_config'>>) => {
            const newConfig = action.payload.data.active_config
            state.activeConfigs.push(newConfig)
        },
        [deleteActiveConfigById.fulfilled.type]: (state, action: PayloadAction<IActiveConfigDeleteResponse>) => {
            state.deleteMessage = action.payload.data.message
            state.activeConfigs = state.activeConfigs.filter(activeConfig => activeConfig.id !== action.payload.activeConfigId);
        },
    },
})

export default activeConfigsSlice.reducer
