export const update = <T>(array: T[], findIndex: number,
                          callbackFind: (elem: T) => T,
                          callbackNotFind?: () => T) => {
    if (findIndex !== -1) {
        return array.map((elem, i) => {
            if (i === findIndex) return callbackFind(elem)
            return elem
        })
    }
    if (callbackNotFind) return [...array, callbackNotFind()]
    return array
}
