import { IResponse } from "@/types/type";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { purchaseItem } from "./actions";
import {IPaymentLink} from '@/store/actionTypes/purchase'

export interface PurchaseState {
    isSuccess: boolean
    isError: boolean
    purchaseIsLoading: boolean
    paymentLink: IPaymentLink | null
    errorMessage: string
}
const initialState: PurchaseState = {
    isSuccess: false,
    purchaseIsLoading: false,
    isError: false,
    paymentLink: null,
    errorMessage: ''
}

export const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        resetPaymentLink: (state) => {
            state.paymentLink = null
        }
    },
    extraReducers: {
        [purchaseItem.pending.type]: (state) => {
            state.purchaseIsLoading = true

        },
        [purchaseItem.fulfilled.type]: (state, action: PayloadAction<IPaymentLink>) => {
            state.purchaseIsLoading = false
            state.isSuccess = true
            state.isError = false
            state.paymentLink = action.payload
        },
        [purchaseItem.rejected.type]: (state, action: PayloadAction<any>) => {
            state.purchaseIsLoading = false
            state.isError = true
            state.isSuccess = false
            state.errorMessage = action.payload.message || 'Неизвестная ошибка'
        }
    },
})

export const { resetPaymentLink } = purchaseSlice.actions
