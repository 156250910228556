import {RolesId} from '@/constants/roles/roles'

export type Role = 'moderator' | 'admin' | 'user'

export const getRoleString = (roleId: string): Role | null => {
    switch (roleId) {
        case RolesId.user: return 'user'
        case RolesId.moderator: return 'moderator'
        case RolesId.admin: return 'admin'
        default: return null
    }
}
