import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { Api } from "@/api/apiService";
import { ConfigSchema } from "@/api/swaggerApi/translator";

import { IGetConfigById } from "@/store/actionTypes/configs";

export const getListOfConfigs = createAsyncThunk('/configs', async (page: number, thunkAPI) => {
    try {
        const response = await Api.Config.getConfigsConfigsGet(null, page)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const postCreateNewConfig = createAsyncThunk('/configs/postCreateNewConfig', async (arg: ConfigSchema, thunkAPI) => {
    try {
        const response = await Api.Config.createConfigConfigsPost(arg)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const getConfigById = createAsyncThunk('/configs/getPromptById', async (arg: IGetConfigById, thunkAPI) => {
    try {
        const response = await Api.Config.getConfigConfigsConfigIdGet(arg)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const putConfigById = createAsyncThunk('/configs/putConfigById', async ({configId, body }:{configId:number, body:ConfigSchema} , thunkAPI) => {
    try {
        const response = await Api.Config.updateConfigConfigsConfigIdPut(configId, body)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const deleteConfigById = createAsyncThunk('/configs/deleteConfigById', async (configId: number, thunkAPI) => {
    try {
        const response = await Api.Config.deleteConfigConfigsConfigIdDelete(configId)
        return {
            data: response.data,
            configId: configId
        }
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data){
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})