import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'
import {IGetTransactionsParams} from '@/store/actionTypes/transactions'

export const getTransactions = createAsyncThunk(
    '/transactions/',
    async (data: IGetTransactionsParams | undefined, thunkAPI) => {
        try {
            const response = await Api.Transaction.getTransactionsTransactionsGet(
                data?.status || null,
                data?.page || 1,
                data?.perPage || 10
            )
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
