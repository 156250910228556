import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {List, Title} from '@mantine/core'

import {getRoleString} from '@/utils/roles/getRoleString'

import CenterPage from '@/ui/CenterPage/CenterPage'

import SiteMapItem from '@/pages/SiteMap/SiteMapItem/SiteMapItem'
import {AuthType, siteMapNavigation} from '@/pages/SiteMap/siteMapNavigation'
import {useAppSelector} from '@/store/hooks'

const SiteMap: FC = () => {
    const { t } = useTranslation()
    const { user } = useAppSelector(state => state.auth)

    const isMatchToCurrentAuthType = (authType: AuthType): boolean => {
        if (authType === 'any') return true
        if (!user && authType === 'notAuth') return true
        if (authType === 'auth') return true
        if (user){
            const role = getRoleString(user.user_info.role_id)
            return role === authType
        }
        return false
    }

    const generateElements = () => {
        return siteMapNavigation.map((element, i) => {
            if (isMatchToCurrentAuthType(element.authType))
                return <SiteMapItem link={element.link} key={i}>{element.name}</SiteMapItem>
            return <SiteMapItem link={element.link} key={i}>{element.name} {'*'}</SiteMapItem>
        })
    }

    return (
        <CenterPage>
            <Title ta={'center'} order={2}>
                {t('Map.Site map')}
            </Title>
            <List listStyleType={'none'} mt={20}>
                {generateElements()}
            </List>
        </CenterPage>
    )
}

export default SiteMap
