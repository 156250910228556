import {IBaseStateField, IPagination, IRejected} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {ITranslation, ITranslationResponseState} from '@/store/actionTypes/translation'
import {getTranslations} from '@/store/entities/translation/actions'

interface TranslationsState extends IBaseStateField {
    translations: ITranslation[]
    pagination: IPagination
    message: null | string
}

const initialState: TranslationsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    translations: [] as ITranslation[],
    pagination: {} as IPagination,
    message: null,
}

export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.page = action.payload
        },
    },
    extraReducers: {
        // Список переводов
        [getTranslations.pending.type]: (state) => {
            state.isLoading = true
            state.isError = false
        },
        [getTranslations.fulfilled.type]: (
            state,
            action: PayloadAction<ITranslationResponseState>
        ) => {
            state.isLoading = false
            state.isSuccess = true
            state.translations = action.payload.translations
            state.pagination = action.payload.pagination
        },
        [getTranslations.rejected.type]: (state, action: PayloadAction<IRejected>) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload?.message || 'Failed to get translations'
        },
    },
})

export default translationSlice.reducer
