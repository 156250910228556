import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'
import {
    ICreatePriceToItemParams,
    IGetItemByIdParams,
    IGetItemsParams,
    IProductCreate,
} from '@/store/actionTypes/products'

export const getItems = createAsyncThunk(
    '/items/',
    async (data: IGetItemsParams | undefined, thunkAPI) => {
        try {
            const response = await Api.Items.getItemsItemsGet(
                data?.type || null,
                data?.page || 1,
                data?.perPage || 10
            )
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const getPricesByItemId = createAsyncThunk(
    '/items/prices',
    async (data: IGetItemByIdParams, thunkAPI) => {
        try {
            const {item_id} = data
            const response = await Api.Items.getItemPriceListItemsPricesGet(item_id)
            return {
                ...response.data,
                item_id,
            }
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const createPriceToItem = createAsyncThunk(
    '/items/item_id/prices',
    async (data: ICreatePriceToItemParams, thunkAPI) => {
        try {
            const {item_id, price} = data
            const response = await Api.Items.createOrUpdateItemPriceItemsItemIdPricePost(
                item_id,
                price
            )
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const createItem = createAsyncThunk(
    '/items/create',
    async (data: IProductCreate, thunkAPI) => {
        try {
            const response = await Api.Items.addItemItemsPost(data)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
