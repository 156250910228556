import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService';
import { ICreateArticleParams } from '@/store/actionTypes/articles';

export const createArticle = createAsyncThunk(
  'articles/createArticle',
  async ({ title, text, language_id }: ICreateArticleParams, thunkAPI) => {
    try {
      const article = { title, text, language_id };

      const response = await Api.Articles.createArticleArticlesPost(article);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при создании статьи');
    }
  }
);