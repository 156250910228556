import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService'
import { IFetchArticlesParams, IDeleteArticleParams } from '@/store/actionTypes/articles';

export const fetchArticles = createAsyncThunk(
  'articles/fetchArticles',
  async ({ page, perPage, sortBy, orderBy }: IFetchArticlesParams, thunkAPI) => {
    try {
      const response = await Api.Articles.getArticlesArticlesGet({
        isOriginal: undefined,
        originalArticleId: undefined,
        languageId: undefined,
        title: undefined,
        page,
        perPage,
        sortBy,
        orderBy
      });

      const { data, pagination } = response.data;

      return { articles: data.list, pagination };
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при получении статей');
    }
  }
);

export const deleteArticle = createAsyncThunk(
  'articles/deleteArticle',
  async ({ articleId }: IDeleteArticleParams, thunkAPI) => {
    try {
      await Api.Articles.deleteArticleArticlesArticleIdDelete(articleId);
      return articleId;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при удалении статьи');
    }
  }
);