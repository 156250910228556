import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getSubscriptions, updateSubscriptions} from '@/store/entities/settings/actions'
import {IGetSubscriptionsReturn, IUpdateSubscriptionsReturn} from '@/store/actionTypes/settings'

interface SettingsState {
    getSubscriptionsState: IBaseStateField
    updateSubscriptionsState: IBaseStateField
    isSendMarketingEmail: boolean | null
}

const initialState: SettingsState = {
    getSubscriptionsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    updateSubscriptionsState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    isSendMarketingEmail: null
}

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        resetSettingsStates: (state) => {
            state.getSubscriptionsState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
            state.updateSubscriptionsState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        }
    },
    extraReducers: {
        [getSubscriptions.pending.type]: (state) => {
            state.getSubscriptionsState.isLoading = true
            state.getSubscriptionsState.isSuccess = false
            state.getSubscriptionsState.isError = false
        },
        [getSubscriptions.fulfilled.type]: (state, action: PayloadAction<IGetSubscriptionsReturn>) => {
            state.getSubscriptionsState.isLoading = false
            state.getSubscriptionsState.isSuccess = true
            state.getSubscriptionsState.isError = false

            state.isSendMarketingEmail = action.payload.send_marketing_email
        },
        [getSubscriptions.rejected.type]: (state) => {
            state.getSubscriptionsState.isLoading = false
            state.getSubscriptionsState.isSuccess = false
            state.getSubscriptionsState.isError = true
        },
        [updateSubscriptions.pending.type]: (state) => {
            state.updateSubscriptionsState.isLoading = true
            state.updateSubscriptionsState.isSuccess = false
            state.updateSubscriptionsState.isError = false
        },
        [updateSubscriptions.fulfilled.type]: (state, action: PayloadAction<IUpdateSubscriptionsReturn>) => {
            state.updateSubscriptionsState.isLoading = false
            state.updateSubscriptionsState.isSuccess = true
            state.updateSubscriptionsState.isError = false

            state.isSendMarketingEmail = action.payload.send_marketing_email
        },
        [updateSubscriptions.rejected.type]: (state) => {
            state.updateSubscriptionsState.isLoading = false
            state.updateSubscriptionsState.isSuccess = false
            state.updateSubscriptionsState.isError = true
        },
    }
})

export default settingsSlice.reducer
